export default class TranslationTest {
    static instance = null;
    constructor() {
        this.submitForm = this.submitForm.bind(this);
        this.analyze = this.analyze.bind(this);
        this.cleanResult = this.cleanResult.bind(this);

        $(document).on('submit', '#translationTestForm', this.submitForm);
        $(document).on('click', '#translationTestForm #js-analyze', this.analyze);
        $(document).on('click', '#js-clean', this.cleanResult);
    }


    analyze(e) {
        e.preventDefault();
        let targetBtn = $(e.target);
        let url = $(e.target).data('route');
        let data = $('#translationTestForm [name="urls"]').val();

        let lastText = targetBtn.text();
        targetBtn.attr('disabled', 'disabled').text('Analyzing...');

        $.ajax({
            type: 'post',
            url: url,
            data: {urls: data},
            success: (result) => {
                if (result.error) {
                    alert(result.error);
                } else {
                    $('#translationTestForm [name="style_json"]').val(result.text);
                }
            },
            error: (err) => {
                console.log(err);
            },
            complete: () => {
                targetBtn.removeAttr('disabled').text(lastText);
            }
        })
    }


    submitForm(e) {
        e.preventDefault();
        let targetBtn = $(e.target).find('button[type="submit"]');
        let url = $(e.target).attr('action');
        let data = $(e.target).serialize();
        let lastText = targetBtn.text();
        targetBtn.attr('disabled', 'disabled').text('Sending...');

        $.ajax({
            type: 'post',
            url: url,
            data: data,
            success: (result) => {
                if (result.error) {
                    alert(result.error);
                } else {
                    // merge the result with the original text
                    let originalText = $('#js-translation-result').html();
                    $('#js-translation-result').html(originalText + result.text + '<br><hr/><br>');
                }
            },
            error: (err) => {
                console.log(err);
            },
            complete: () => {
                targetBtn.removeAttr('disabled').text(lastText);
            }
        })
    }

    cleanResult(e) {
        e.preventDefault();
        $('#js-translation-result').html('');
    }

    static init() {
        if (!this.instance) {
            this.instance = new TranslationTest();
        }
    }


}
